<script setup>
const props = defineProps({
  variant: { type: String, default: "body" },
  as: { type: String, default: "p" },
  color: { type: String, default: "default" },
});

// heading_1
// heading_2
// heading_3
// heading_3

// widget_title
// widget_subtitle

// body
// body_sm
// body_lg

// body_light
// body_light_sm
// body_light_lg

// body_strong
// body_strong_sm
// body_strong_lg

const sizeClasses = computed(() => {
  switch (props.variant) {
    case "heading_0":
      return "text-[30px] leading-tight";
    case "heading_1":
      return "text-[26px] leading-[32px]";
    case "heading_2":
    case "widget_title":
      return "text-[20px] leading-[26px]";
    case "heading_3":
      return "text-[16px] leading-[20px]";
    case "body":
    case "body_light":
    case "body_strong":
    case "body_semibold":
      return "text leading-[22px]";
    case "body_sm":
    case "body_light_sm":
    case "body_strong_sm":
      return "text-[12px] leading-[20px]";
    case "body_lg":
    case "body_light_lg":
    case "body_strong_lg":
      return "text leading-[22px] md:text-[16px] md:leading-[24px]";
    default:
      return "text";
  }
});

const weightClasses = computed(() => {
  switch (props.variant) {
    case "heading_0":
    case "heading_1":
    case "heading_2":
    case "heading_3":
    case "widget_title":
      return "font-black";

    case "body_strong":
    case "body_strong_sm":
    case "body_strong_lg":
      return "font-bold";

    case "body_semibold":
      return "font-semibold";

    default:
      return "font-normal";
  }
});

const colorClasses = computed(() => {
  switch (props.color) {
    case "unset":
      return;
    case "inverted":
      return "text-mono-white";
    case "disabled":
      return "text-mono-light-grey";
    case "destructive":
      return "text-destructive";
    case "helper":
      return "text-mono-grey";
  }

  switch (props.variant) {
    case "body_strong":
    case "body_strong_sm":
    case "body_strong_lg":
    case "heading_0":
    case "heading_1":
    case "heading_2":
    case "heading_3":
    case "widget_title":
      return "text-mono-black";

    case "body_light":
    case "body_light_sm":
    case "body_light_lg":
      return "text-mono-grey";
  }

  return "text-mono-dark-grey";
});
</script>

<template>
  <component :is="as" :class="[sizeClasses, weightClasses, colorClasses]"
    ><slot
  /></component>
</template>
